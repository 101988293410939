//= require slick-carousel/slick/slick.js

$(function() {
  var conf = {
    slideshow: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  };

  $('.c-slick').each(function() {
    // return;
    var $this = $(this);

    var component = $this.data('slick-component');
    var $component = $this.closest('.c-' + component);
    var slick_class = 'c-' + component + '--slick';
    if ($component.hasClass(slick_class)) return;
    $component.addClass(slick_class);

    $this.slick($.extend({
      dots: false,
      infinite: false,
      speed: 300,
      prevArrow: $this.prev(),
      nextArrow: $this.next(),
    }, conf[component]));
  })
});
